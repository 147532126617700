import React from 'react'
import { Heading, Paragraph } from 'grommet'

import { Layout } from '../layout'
import SEO from '../components/seo'

const AboutPage = () => (
  <Layout>
    <SEO title="Kontakt" />
    <Heading>O nas</Heading>
    <Heading level={3} className="fill">
      Historia
    </Heading>
    <ul>
      <li>
        2005r. – rozpoczęcie badań nad nanobiotechnologią (E. Sawosz i M.
        Grodzik)
      </li>
      <li>
        2009r. - pierwsza publikacja Sawosz E., Grodzik M., Zielińska M.,
        Niemiec T., Olszańska B., Chwalibóg A. 2009 Nanoparticles of silver do
        not affect growth, development and DNA oxidative damage in chicken
        embryos. Arch. Geflügelk., 73, 208-213
      </li>
      <li>
        2011r. - pierwszy patent PL390632-A1 (2011) - Sawosz E., Grodzik M.,
        Zielińska M., Niemiec T.: "Środek poprawiający przyrost mięśni u drobiu”
      </li>
      <li>
        2013r. - powstanie Zakładu Nanobiotechnologii na Wydziale Nauk o
        Zwierzętach SGGW w Warszawie
      </li>
      <li>
        2019r. - powstanie Samodzielnego Zakładu Nanobiotechnologii i Ekologii
        Doświadczalnej w Instytucie Biologii SGGW w Warszawie
      </li>
      <li>
        2020r. - powstanie Katedry Nanobiotechnologii <br/> 	&emsp;	&emsp;	&nbsp; - około 100 publikacji IF z
        zakresu Nanobiotechnologii
      </li>
    </ul>
    <Heading level={3} className="fill">
      Nasze nanomateriały
    </Heading>
    <Paragraph fill>
      Głównym obiektem naszych badań są właściwości rozmaitych nanomateriałów:
      <br />- nanocząstki metali, takich jak: złoto, srebro, platyna oraz tlenki
      metali
      <br />- alotropowe formy węgla (grafen, tlenek grafenu, diament)
      <br/>- nanofilmy i nanoskafoldy 3D
    </Paragraph>

    <Heading level={3} className="fill">
      Nasze modele biologiczne
    </Heading>
    <Paragraph fill>
      Nasze cele badawcze realizujemy z zastosowaniem
      nowoczesnych i różnorodnych modeli biologicznych:
    </Paragraph>
    <ul>
      <li>
        Hodowle 2D: linie komórkowe i hodowle pierwotne, wyprowadzone m.in. z
        mięśni kurzych embrionów
      </li>
      <li>Hodowle przestrzenne (3D): sferoidy, tkanki i sztuczna skóra</li>
      <li>Zarodek kury: CAM, hodowla guzów in ovo</li>
      <li>Zwierzęta (gryzonie)</li>
    </ul>
  </Layout>
)

export default AboutPage
